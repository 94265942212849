import React from "react";
import { graphql } from "gatsby";
import Layout from "components/layout";
import Gallery from "components/gallery";
import styles from "./styles.module.css";

export const query = graphql`
  query PhotosPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        photos {
          image {
            childImageSharp {
              fluid(maxWidth: 500, quality: 72) {
                ...GatsbyImageSharpFluid
              }
            }
            publicURL
          }
        }
      }
      fields {
        slug
      }
    }
  }
`;

const PhotosTemplate = ({ data }) => {
  const { title, photos } = data.markdownRemark.frontmatter;

  return (
    <Layout title={title}>
      <div className={styles.photos}>
        <h1>{title}</h1>
        <Gallery images={photos.map(photo => photo.image)} />
      </div>
    </Layout>
  );
};

export default PhotosTemplate;
