import React from "react";
// import ZoomImage from "components/zoom-image";
import Image from "components/image";
import styles from "./styles.module.css";

const Gallery = ({ images }) => {
  return (
    <div className={styles.gallery}>
      {images.map((image, i) => (
        // <ZoomImage key={i} data={image} />
        <Image key={i} data={image} />
      ))}
    </div>
  );
};

export default Gallery;
